import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { LightspeedSyncMode } from '@/enums/LightspeedSyncMode';

export default class LightspeedApiService extends ApiService<any> {
  constructor() {
    super('lightspeed');
  }

  public syncFoodcard(payload: { venue: string; mode: LightspeedSyncMode }): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/foodcard/sync`, payload);
  }
}
